import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`AppCenter for Everyone: `}<a parentName="li" {...{
          "href": "https://www.indiegogo.com/projects/appcenter-for-everyone#/"
        }}>{`https://www.indiegogo.com/projects/appcenter-for-everyone#/`}</a></li>
      <li parentName="ul">{`Plasma är mest poppis enligt pkgstats: `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/kde/comments/f7spo9/plasma_is_the_most_popular_desktop_among/"
        }}>{`https://www.reddit.com/r/kde/comments/f7spo9/plasma_is_the_most_popular_desktop_among/`}</a></li>
      <li parentName="ul">{`npm:s donationsplattform försenad: `}<a parentName="li" {...{
          "href": "https://www.theregister.co.uk/2020/02/22/npm_funding_source/"
        }}>{`https://www.theregister.co.uk/2020/02/22/npm_funding_source/`}</a></li>
      <li parentName="ul">{`Firefox Private Network ute som beta: `}<a parentName="li" {...{
          "href": "https://fpn.firefox.com/"
        }}>{`https://fpn.firefox.com/`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`GDPR & popups & cookie-bars: `}<a parentName="li" {...{
          "href": "https://arxiv.org/abs/1909.02638"
        }}>{`https://arxiv.org/abs/1909.02638`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`AI och ML hjälper organdonationer: `}<a parentName="li" {...{
          "href": "https://qz.com/1383083/how-ai-changed-organ-donation-in-the-us/"
        }}>{`https://qz.com/1383083/how-ai-changed-organ-donation-in-the-us/`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex PinePhone`}</h3>
    <ul>
      <li parentName="ul">{`postmarketOS: `}<a parentName="li" {...{
          "href": "https://postmarketos.org/"
        }}>{`https://postmarketos.org/`}</a></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Bittin bidrar`}</li>
    </ul>
    <h3>{`Contributor-ligan`}</h3>
    <h4>{`Alex`}</h4>
    <ul>
      <li parentName="ul">{`Brons: 1`}</li>
      <li parentName="ul">{`Silver: 0`}</li>
      <li parentName="ul">{`Guld: 0`}</li>
    </ul>
    <h4>{`Seb`}</h4>
    <ul>
      <li parentName="ul">{`Brons: 1`}</li>
      <li parentName="ul">{`Silver: 0`}</li>
      <li parentName="ul">{`Guld: 0`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Mystical Sting" av Alexander Nakarada`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      